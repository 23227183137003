import 'bootstrap';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

if (module.hot) {
  module.hot.accept();
}

$(document).ready(function() {

  // Gets the video src from the data-src on each button
  
  var $videoSrc;  
  $('.video-btn').click(function() {
      $videoSrc = $(this).data( "src" );
  });
  console.log($videoSrc);
  
  $('#videoModal').on('shown.bs.modal', function (e) {
    $("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" ); 
  });

  $('.close').click(function() {
    $("#video").attr('src', ''); 
  });

});